@import '../../../styles/colors.scss';

.profile {
    margin-top: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: src("img/bird.png");
    color: $secondary-color;

    &__infos {
        background-color: white;
        width: 30%;
        padding: 20px;

        h1 {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }

        label {
            width: 100%;
            margin-bottom: 20px;
            input, select {
                border: 2px solid lightgrey;
                padding: 6px 20px;
                font-size: 15px;
                width: 100%;
                margin-top: 5px;
                &:focus {
                  outline: none;
                  border: 2px solid white;
                }
                &::placeholder {
                  color: lightgrey;
                }
            }
        }
    }

    &__avatar {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        position: relative;
        label {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 0;
            display: flex;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.479);
            opacity: 0;
            transition: opacity 0.5s ease;
            img {
                width: 30px;
            }
        }
        input {
            display: none;
        }
        div {
            background-color: $border-blue;
            background-size: cover;
            background-position: center;
            width: 100px;
            height: 100px;
        }
    }

    &__btnGroup {
        display: flex;
        justify-content: space-between;
        button {
            width: 100%;
        }
    }

    .illustration {
        position: absolute;
        z-index: -1;
        bottom: -400px;
      }
}