@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  height: unset;
}

.flex {
    display: flex;
    align-items: center;
}

button {
  background-color: transparent;
  border: none;
}

button:focus,
button:active {
  outline: none;
}

.errorText {
  color: #cf2323;
  font-size: 11px;
  font-weight: normal;
}

.purple-btn {
  background-color: #4b0aa3;
  border: none;
  color: white;
  font-weight: bold;
  padding: 0 12px;
  height: 34px;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #4b0aa3;
    border: 2px solid #4b0aa3;
    text-decoration: none;
  }
  &:focus {
    background-color: #AA12B0;
    border: none;
    color: white;
  }
}

.blue-btn {
  background-color: $secondary-color;
  color: white;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: $secondary-color;
    outline: none;
    background-color: white;
    outline: 3px solid $secondary-color;
    outline-offset: -3px;
    text-decoration: none;
  }
  &:focus {
    text-decoration: underline;
  }
}

.blue-btn-outline {
  color: $secondary-color;
  background-color: white;
  outline: 3px solid $secondary-color;
  outline-offset: -3px;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: white;
    outline: none;
    background-color: $secondary-color;
    text-decoration: none;
  }
  &:focus {
    text-decoration: underline;
  }
}

.delete-btn {
  background-color: #cf2323;
  border: none;
  color: white;
  font-weight: bold;
  padding: 0 12px;
  height: 34px;
  &:hover {
    background-color: white;
    color: #cf2323;
    border: 2px solid #cf2323;
  }
  .btn-icon {
    margin-right: 10px;
  }
}

.lightpurple-btn {
  background-color: $secondary-color;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: $secondary-color;
    outline: none;
    background-color: white;
    outline: 3px solid $secondary-color;
    outline-offset: -3px;
    text-decoration: none;
  }
  &:focus {
    text-decoration: underline;
  }
}

.lightpurple-btn-outline {
  color: $tertiary-color;
  background-color: white;
  outline: 3px solid $tertiary-color;
  outline-offset: -3px;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: white;
    outline: none;
    background-color: $tertiary-color;
    text-decoration: none;
  }
  &:focus {
    text-decoration: underline;
  }
}

