.search {
    width: 100vw;
    height: 100vh;
    position: relative;
    &__background {
        position: fixed;
        z-index: -1;
        top: -25%;
        right: 70px;
    }
    &__nav {
        height: 10vh;
        display: flex;
        justify-content: flex-end;
        padding: 54px 20px;
    }
    &__linkLibrary {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-color;
        p {
            margin-right: 10px;
            font-size: 14px;
            font-weight: bold;
            margin: 0 10px;
        }
        img {
            height: 30px;
            width: auto;
        }
        &:hover {
            text-decoration: none;
            color: $tertiary-color;
        }
        &:active {
            text-decoration: underline;
        }
    }
    &__btnLogout {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;
        img {
            width: 30px;
            height: auto;
        }
    }
    &__container {
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    &__logo {
        margin-bottom: 50px;
        z-index: 2;
    }
    &__searchContainer {
        width: 55%;
        &--open {
            border: 1px solid $light-blue-grey;
        }
    }
    &__containerInput {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        align-items: center;
    }
    &__input {
        width: 100%;
        height: 45px;
        border: none;
        z-index: 2;
        padding-left: 50px;
        padding-right: 10px;
        &--img {
            width: 20px;
        }
        &::placeholder {
            color: $border-blue;
            font-weight: lighter;
            font-size: 14px;
        }
        &--noFocus {
            border: 1px solid $light-blue-grey;
            border-right: none;
        }
    }
    &__history {
        position: absolute;
        z-index: 3;
        top: 29%;
        left: 15px;
        bottom: 16rem;
    }
    &__btn {
        height: 40px;
        width: 20%;
        font-size: 17px;
        font-weight: bold;
        margin-top: 20px;
        border: none;
        z-index: 2;
        background-color: $secondary-color;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__error {
        font-size: 10px;
        margin-top: 10px;
        color: $red;
    }
    &__footer {
        height: 10vh;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    &__cgv {
        font-size: 10px;
        font-weight: lighter;
        text-decoration: underline;
        color: #343333;
        position: absolute;
        bottom: 30px;
    }
    &__spreadBtn {
        height: 45px;
        border: 1px solid $light-blue-grey;
        border-left: none;
    }
}
