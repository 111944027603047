.result {
    border-bottom: 1px solid $secondary-color;
    width: 95%;
    padding: 20px 20px 20px 35px;
    display: flex;
    font-size: 13px;
    position: relative;

    .book-cover {
        height: 100px;
        margin-right: 20px;
    }
    &__index {
        position: absolute;
        top: 0%;
        left: 0px;
        font-size: 12px;
        padding: 3px;
        width: 30px;
        height: 20px;
        text-align: center;
        color: #fff;
        background: $secondary-color;
    }

    &__input {
        margin: 5px 10px 0 10px;
    }
    &__title {
        font-size: 16px;
        color: #4c41f2;
        margin-bottom: 0;
    }
    &__identityFile {
        color: #457d3b;
    }
    &__abstract {
        color: #7d7d7d;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    &__picto {
        margin-right: 10px;
    }

    .highlight {
        background-image: linear-gradient(#ffff009c, #ffff009c);
        opacity: 1;
        display: inline;
        padding: 0.45rem 0;
        box-decoration-break: clone;
    }
}
