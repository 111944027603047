
.advancedSearchForm {
    color: $secondary-color;
    padding: 10px;
    font-size: 0.875rem;
    &__title {
        margin: 5px 0;
    }
    &__motor {
        display: flex;
        margin: 0 0 0 10px;
        align-items: center;
        margin-bottom: 10px;
    }
    &__motorInput {
        width: 10px;
        margin-right: 5px
    }
    &__motorLabel {
        margin: 0;
    }
}

.firstSearch {
    display: flex;
    margin-bottom: 5px;
    input {
        border: 1px solid $border-input-lightGrey;
        height: 30px;
        margin-right: 10px;
        padding: 0 10px;
        width: calc(80% - 30px);
        &::placeholder {
            color: $secondary-color;
            font-weight: lighter;
            font-size: 12px;
            opacity: .7;
        }
    }
    select {
        border: 1px solid $border-input-lightGrey;
        height: 30px;
        margin-right: 10px;
        padding: 0 10px;
        color: $secondary-color;
        opacity: .7;
        width: 20%;
    }
    &__selectFilter {
        width: 10%;
        font-size: 12px;
        font-weight: bold;
        padding: 0;
        outline: 1px solid $secondary-color;
    }
}
.buttonsField {
    width: 30px;
    display: flex;
    justify-content: space-between;
    &__button {
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &:disabled {
            opacity: 0.5;
        }
        img {
            width: 10px;
            height: 10px;
        }
    }
}