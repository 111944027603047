// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "breakpoints";
@import "colors";
@import "template";


// Import pages styles
@import '../pages/SearchPage//styles/index.scss';
@import '../pages/SearchResultPage/styles/index.scss';

// Import component styles
@import '../components/Header/styles/index.scss';
@import '../components/SortButtons/styles/index.scss';
@import '../components/Result/styles/index.scss';
@import '../components/ChatDoc/styles/index.scss';
@import '../components/ChatSearchHistory/styles/index.scss';
@import '../components//NewRateHistoryCard/styles/index.scss';
@import '../components/AvancedSearchForm/styles/index.scss'

