#pubmed-article {
  padding: 10px;
  strong {
    font-weight: bold;
  }
  a,
  button {
    color: #0071bc;
  }
  p {
    line-height: 1.5;
  }
  h2,
  h3 {
    font-weight: 700;
    font-size: 1.75rem;
  }
  .heading {
    margin: 0 0 3.6rem;
    .full-view {
      display: block;
      .article-citation {
        padding-bottom: 2rem;
        .article-source {
          color: #5b616b;
          display: inline-block;
          line-height: 1.5;
          .citation-doi {
            color: #5b616b;
            display: inline-block;
            line-height: 1.5;
          }
        }
      }
      .heading-title {
        font-size: 2rem;
        line-height: 1.4;
        margin: 0 0 1rem;
        word-wrap: break-word;
        font-weight: 700;
      }
      .inline-authors {
        margin-bottom: 1rem;
        .authors-list {
          display: inline;
          line-height: 1.5;
          .authors-list-item {
            display: inline-block;
          }
        }
      }
      .short-article-details {
        margin-bottom: 0.3rem;
      }
      .extended-article-details {
        display: none;
        margin-top: 2.4rem;
        position: relative;
        top: -0.3rem;
        padding-left: 1.6rem;
        border-left: 0.4rem solid #d6d7d9;
        color: #212121;
        .affiliations {
          margin: 2.4rem 0;
          .title {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.4rem;
            margin: 0 0 1rem;
          }
          .item-list {
            padding: 0;
            margin: 0;
            display: block;
            list-style: none;
            li {
              position: relative;
              padding-left: 2.4rem;
              .key {
                display: block;
                width: 2.4rem;
                padding-right: 0.4rem;
                line-height: 2.4rem;
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                color: #5b616b;
              }
            }
          }
        }
      }
    }
    .identifiers {
      padding: 0;
      margin: 0;
      display: block;
      list-style: none;
      li {
        display: inline-block;
        margin-right: 1rem;
        margin: 0;
        padding: 0;
      }
    }
    .in-clipboard-label {
      display: none;
    }
    .short-view {
      display: none;
    }
  }
  header {
    display: block;
  }
  .actions-buttons.inline {
    margin: 3.6rem 0;
    display: none;
  }
  .abstract {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    .title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 3.6rem;
      margin-bottom: 1.2rem;
      font-weight: 700;
      clear: both;
    }
    p {
      margin: 1.2rem 0;
    }
  }
  .conflict-of-interest {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    word-wrap: break-word;
    .title {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-top: 3.6rem;
      margin-bottom: 1.2rem;
      font-weight: 700;
    }
    .statement {
      p {
        margin: 0;
      }
    }
  }
  .dropdown-block {
    position: relative;
    display: inline-block;
  }

  .figures {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    .title {
      line-height: 2.4rem;
      margin-top: 3.6rem;
      margin-bottom: 1.2rem;
    }
    .figures-list {
      margin-bottom: -2.4rem;
      margin-right: -2.4rem;
      .figure-item {
        max-width: 20rem;
        display: inline-block;
        margin: 0 2.4rem 2.4rem 0;
        vertical-align: bottom;
        cursor: pointer;
        .figure-thumb {
          max-width: calc(100% - 1rem);
          max-height: calc(100% - 1rem);
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          border: 0;
        }
        .figure-link {
          display: block;
          height: 18rem;
          text-align: center;
          position: relative;
          width: 20rem;
        }
        .figure-caption {
          width: 20rem;
          height: 87px;
          .caption-wrap {
            max-height: 6rem;
            overflow: hidden;
            display: block;
          }
        }
        .figure-caption-text {
          color: black;
          padding: 1.5rem 2rem;
          background-color: #f1f1f1;
          vertical-align: middle;
          display: table-cell;
        }
        .figure-caption-medium {
          display: none;
        }
      }
    }
  }
  .figure-label,
  .figures .figures-list .figure-item .figure-caption-text .figure-caption-contents,
  .figures .figures-list .figure-item .figure-caption-text div,
  .figures .figures-list .figure-item .figure-caption-text p {
    display: inline;
  }
  .figures .figures-list .figure-item .figure-caption-full,
  .article-page .article-details .figures .figures-list .figure-item .figure-caption-medium {
    display: none;
  }
  .references {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    .article-page .article-details > .references > .title {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-top: 3.6rem;
      margin-bottom: 1.2rem;
    }
    .refs-list {
      word-break: break-word;
    }
    .references-list {
      padding: 0;
      margin: 0;
      display: block;
      list-style: none;
      padding-left: 3rem;
      margin: 1.2rem 0;
      li {
        list-style: decimal outside;
        padding-left: 1rem;
        word-wrap: break-word;
      }
      .references-and-notes-list {
        padding-left: 0;
        margin: 0;
        display: inline-table;
        .skip-numbering {
          list-style-type: none;
          counter-increment: none;
        }
      }
    }
  }

  .keywords-section {
    .title {
      font-size: 1.5rem;
      line-height: 2.4rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    .keywords-list {
      padding: 0;
      margin: 0;
      display: block;
      list-style: none;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: wrap;
      .keyword-actions-trigger {
        color: black;
        cursor: initial;
      }
    }
  }

  .related-links {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    .title {
      font-size: 1.5rem;
      line-height: 2.4rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    .related-links-list {
      padding: 0;
      margin: 0;
      display: block;
      list-style: none;
      margin: 1.2rem 0;
    }
  }

  .linkout {
    margin: 3.6rem 0 0;
    word-break: break-word;
    position: relative;
    .title {
      font-size: 1.5rem;
      line-height: 2.4rem;
      margin-top: 3.6rem;
      margin-bottom: 1.2rem;
    }
    .linkout-list {
      padding: 0;
      margin: 0;
      display: block;
      list-style: none;
      margin: 1.2rem 0;
      .linkout-category {
        font-size: 1rem;
        margin-bottom: 0;
        margin-top: 1.2rem;
      }
      .linkout-category-links {
        padding: 0;
        margin: 0;
        display: block;
        list-style: none;
        li {
          display: list-item;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
