
.rateHistoryCommunity {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $border-blue;
    justify-content: space-between;
    position: relative;
    &__date {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 10px;
        opacity: .6;
    }
    &__title {
        font-size: 0.75rem;
        font-weight: bold;
        font-style: italic;
    }
    &__firstInfo {
        display: flex;
        align-items: center;
        font-size: 0.813rem; 
        padding: 10px 0 0;

    }
    
    &__ratting {
        display: flex;
        font-size: 0.875rem;
        align-items: center;
        width: 200px;
        justify-content: right;
        p {
            margin: 0;
        }
        &--bold {
            font-weight: bold;
            padding-right: 5px;
        }
        &--rate {
            color: $light-purple;
            font-size: 10px;
            padding-left: 5px;
            padding-bottom: 5px;
        }
    }
    &__user {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 85%;
        p {
            margin: 0;
        }
        &--img {
            height: 40px;
            width: 40px;
            background-color: lightgray;
            margin-right: 10px;
        }
        &--searchQuery {
            padding-left: 5px;
            text-decoration: underline;
            font-weight: 600;
        }
    }
    &__buttons {
        padding: 10px 0 0px;
        display: flex;
        justify-content: right;
        align-items: center;
        button {
            width: 101px;
            height: 30px;
            border: 3px solid $secondary-color-lighter;
            margin-left: 10px;
            color: $secondary-color-lighter;
            font-weight: bold;
            font-size: 0.8rem;
        }
        a {
            width: 130px;
            height: 30px;
            border: 3px solid $secondary-color-lighter;
            margin-left: 10px;
            color: $secondary-color-lighter;
            font-weight: bold;
            font-size: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &--purple {
            background-color: $secondary-color-lighter;
            color: #fff !important;
        }
    }
}