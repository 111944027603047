$primary-color: #FFF;
$secondary-color: #0A3B93;
$secondary-color-middle: #0F278C;
$secondary-color-lighter: #2C46B1;
$tertiary-color: #8A7B60;
$tertiary-faded: #C4BDAF;
$third-color: #097299;
$purple: #4B0AA3;
$light-purple: #9D0AA3;
$light-blue: #F9F8F7;
$light-blue-grey: rgba(198, 205, 234, 0.54);
$border-input-lightGrey: #D1D1D1;
$border-blue: #D5D9EB;
$selection-pink: #D7C5F0;
$red: #B12C2C;
$bg-grey: #F0F2F8;

$purple2: #6e2dc7;