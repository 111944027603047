.resultsPage {
    position: relative;

    // search bar ----------------------------------------------------------------------------------------------------
    &__inputSearch{
        background-color: $primary-color;
        border: 1px solid $light-blue-grey;
        display: flex;
        width: 80%;
        align-items: center;
        &--input {
            width: calc(100% - 90px);
            position: relative;
            height: 50px;
            z-index: 3;
            background-color: $primary-color;
            border: none;
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $light-blue-grey;
                font-size: 12px;
                font-weight: lighter;
            }
            &:disabled {
                background-color: rgb(247, 247, 247);
                color: $secondary-color-lighter;
            }
        }
        &--label {
            font-size: 10px;
            text-align: right;
            padding-right: 20%;
            color: $secondary-color;
            font-style: italic;
            margin-bottom: 0;
        }
        &--btnRight {
            background-color: $secondary-color;
            color: $primary-color;
            font-size: 14px;
            font-weight: bold;
            width: 65px;
            padding-left: 25px;
            height: 45px;
            margin-left: -20px;
            position: relative;
            outline: none;
            &:disabled {
                background-color: #0f288c9c;
                color: #c1cdfc;
            }
            img {
                position: absolute;
                left: 45%;
                top: 30%;
                height: 20px;
                width: 20px;
                z-index: 1;
            }
        }
        &--btnRightTwo {
            background-color: $primary-color;
            color: $primary-color;
            font-size: 14px;
            font-weight: bold;
            width: 85px;
            height: 45px;
            z-index: 2;
            margin-left: -20px;
            position: relative;
            outline: none;
            padding-left: 25px;
            &:disabled {
                background-color: #0f288c9c;
                color: #c1cdfc;
            }
            img {
                position: absolute;
                left: 48%;
                top: 0%;
                height: 45px;
                width: 40px;
                z-index: 1;
            }
        }
        &--btnLeft {
            font-size: 14px;
            font-weight: bold;
            width: 40px;
            background-color: $primary-color;
            height: 45px;
            z-index: 2;
            position: relative;
            outline: none;
            &:disabled {
                background-color: #0f288c9c;
                color: #c1cdfc;
            }
        }
        &--img {
            position: absolute;
            right: 45%;
            top: 30%;
            height: 20px;
            width: 20px;
            z-index: 1;
        }
    }
    &__sectionSearch {
        margin-top: -30px;
        padding-left: 30px;
        position: fixed;
        width: 100%;
        padding-top: 30px;
        z-index: 1;
        background-color: $primary-color;
    }
    &__container {
        margin-left: 20vw;
        width: calc(55vw - 30px);
        position: relative;
    }
    &__advancedOpen {
        position: absolute;
        padding: 20px 10px 10px;
        background-color: $primary-color;
        width: 80%;
        z-index: 1;
        left: 0;
        top: 40px;
        border: 1px solid $light-blue-grey;
    }
    &__searchBtn {
        height: 30px;
        width: 120px;
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
        border: none;
        background-color: $secondary-color;
        color: white;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // filters ------------------------------------------------------------------------------------------------------
    &__nav {
        top: calc(17vh + 135px);
        width: 20vw;
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0 0 0;
        overflow-y: scroll;
        z-index: 2;
    }
    &__export {
        margin-left: -150px;
        img {
            width: 150px;
        }
        p {
            color: #0f288c;
            font-weight: bold;
        }
    }
    &__containerFilters {
        width: 63%;
        .sortByLabel {
            font-style: italic;
            color: grey;
        }
    }
    &__filtersTitle {
        font-size: 10px;
        text-transform: uppercase;
        color: grey;
        margin: 15px 0;
    }
    &__filterCategory {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        div {
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            min-width: 150px;
            margin-bottom: 5px;
            input {
                margin-right: 10px;
                &.from-to {
                    width: 55px;
                    height: 28px;
                    padding-left: auto;
                    padding-right: auto;
                    text-align: center;
                    border: 1px solid $border-input-lightGrey;
                }
            }
            label {
                margin: 0;
            }
        }
        &--filterSortBy {
            margin-bottom: 20px;
            padding-bottom: 30px;
            width: 160px;
            border-bottom: 1px solid lightgray;
            label {
                margin-bottom: 10px;
            }
            select {
                padding: 5px;
                width: 150px;
                font-size: 12px;
            }
        }
    }

    &__btnFilterEspacenet {
        width: 120px;
        font-size: 12px;
        height: 30px;
        margin-top: 20px;
        font-weight: bold;
    }

    // results articles ---------------------------------------------------------------------------------------------
    &__articles {
        margin-left: 20vw;
        padding-left: 30px;
        padding-right: 30px;
        width: 55vw;
        padding-top: 135px;
    }
    &__noFound {
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            filter: opacity(0.1);
            width: 350px;
            margin-right: 200px;
        }
        p {
            position: absolute;
            color: $secondary-color;
            filter: none;
            font-weight: bold;
            font-size: 20px;
        }
    }
    &__noMore {
        text-align: center;
        padding: 10px 0 200px;
        font-weight: bold;
        font-size: 16px;
    }
    &__nbFound {
        text-align: right;
    }
    &__head {
        align-items: baseline;
        margin-right: 5px;
        font-weight: lighter;
        font-style: italic;
        font-size: 14px;
        color: $secondary-color-middle;
        justify-content: space-between;
        input {
            margin-right: 5px;
        }
    }
    &__loading {
        font-size: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 130px;
        text-indent: -9999em;
        width: 10em;
        height: 10em;
        background: $secondary-color;
        background: -moz-linear-gradient(left, $secondary-color 50%, lightgray 50%);
        background: -webkit-linear-gradient(left, $secondary-color 50%, lightgray 50%);
        background: -o-linear-gradient(left, $secondary-color 50%, lightgray 50%);
        background: -ms-linear-gradient(left, $secondary-color 50%, lightgray 50%);
        background: linear-gradient(to right, $secondary-color 50%, lightgray 50%);
        position: relative;
        -webkit-animation: load3 2.5s infinite linear;
        animation: load3 2.5s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 60px;
        &:after {
            background: #fff;
            width: 75%;
            height: 75%;
            content: "";
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 60px;
        }
        &--text {
            width: 100%;
            text-align: center;
            margin-top: 15px;
            color: $secondary-color;
            font-weight: 600;
            font-size: 1.1rem;
            
        }
    }

    // community section -------------------------------------------------------------------------------------------
    &__openChat {
        position: fixed;
        z-index: 1;
        bottom: 10px;
        right: 10px;
        width: 70px;
        height: 70px;
        img {
            width: 40px;
        }
    }
    &__noDoc {
        text-align: center;
        color: #8692C5;
        font-size: 16px;
        margin-top: 50px;
        padding: 0 30px;
    }
    &__chat {
        overflow-y: auto;
        border-left: 1px solid $tertiary-faded;
        width: calc(25vw - 10px);
        height: 80%;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 0;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        margin: 10px;
        &--conditionalOpenInCommunity {
            -webkit-animation: conditionalOpenInCommunity .5s normal forwards ease-in-out;
            -moz-animation: conditionalOpenInCommunity .5s normal forwards ease-in-out;
            animation: conditionalOpenInCommunity .5s normal forwards ease-in-out;
            -webkit-transform-origin: 100% 0%;
            -moz-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
        }
        &--conditionalOpenInCommunities {
            -webkit-animation: conditionalOpenInCommunities .5s normal forwards ease-in-out;
            -moz-animation: conditionalOpenInCommunities .5s normal forwards ease-in-out;
            animation: conditionalOpenInCommunities .5s normal forwards ease-in-out;
            -webkit-transform-origin: 100% 0%;
            -moz-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
        }
    }

    &__chatTitle {
        color: $secondary-color;
        font-size: 22px;
        text-transform: capitalize;
        padding-top: 10px;
        font-weight: 600;
        &--subtitle {
            font-size: 16px;
            width: 86%;
            margin-bottom: 5px;
        }
    }
    &__select {
        height: 30px;
        width: 86%;
        border: none;
        margin-top: 10px;
        padding: 0 10px;
        text-transform: capitalize;
        color: $tertiary-color;
        margin-bottom: 10px;
        font-weight: bold;
    }
    &__groups {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        img {
            margin-left: 20px;
            width: 15px;
            height: auto;
        }
    }
    &__rating {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 87%;
        margin: 20px 0 0px 0;
        p {
            color: $secondary-color;
            font-size: 18px;
            font-weight: 600;
            span {
                font-size: 12px;
                font-weight: normal;
            }
        }
        img {
            width: 15px;
            height: auto;
        }
    }

    &__selectedDocs {
        width: 86%;
    }

    &__shareBox {
        width: 86%;
        justify-content: center;
        padding: 15px;
        margin-top: 20px;
        img {
            width: 20px;
        }
        p {
            max-width: 80px;
            text-align: center;
            color: #0f288c;
            margin-top: 5px;
            font-size: 12px;
        }
    }

    &__containerChat {
        width: 86%;
        background-color: #acacac17;
        height: 65%;
        overflow: auto;
        position: relative;
        display: flex;
        justify-content: center;
    }
    &__containerChatEmojiOpen {
        width: 86%;
        overflow: auto;
        background-color: #acacac17;
        height: 40%;
        position: relative;
        display: flex;
        justify-content: center;
    }
    &__containerMessages {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: left;
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: thin;
        overflow-x: hidden;
        margin-bottom: 50px;
        position: relative;
    }
    &__chatInput {
        position: absolute;
        bottom: 10px;
        width: 95%;
        background-color: #ebebeb;
        border: none;
        height: 35px;
        padding-left: 45px;
        padding-right: 40px;
        &::placeholder {
            font-size: 12px;
            color: $secondary-color;
        }
        &--emoji {
            position: absolute;
            bottom: 16px;
            left: 5%;
            background: transparent;
            img {
                width: 15px;
            }
        }
        &--submit {
            position: absolute;
            bottom: 16px;
            right: 5%;
            img {
                width: 15px;
            }
        }
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



@-webkit-keyframes conditionalOpenInCommunities {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }
  @-moz-keyframes conditionalOpenInCommunities {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }
  @keyframes conditionalOpenInCommunities {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }


@-webkit-keyframes conditionalOpenInCommunity {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }
  @-moz-keyframes conditionalOpenInCommunity {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }
  @keyframes conditionalOpenInCommunity {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
  }
