.header {
    display: flex;
    width: 100vw;
    align-items: center;
    padding: 20px 20px 50px;
    justify-content: space-between;
    background: #FFFFFF;
    position: fixed;
    z-index: 2;
    top: 0;
    overflow: hidden;

    &__btnLogout {
        margin-right: 10px;
        color: $secondary-color;
        font-size: 16px;
        font-weight: bold;
        margin: 0 10px;
        &:hover {
            color: $tertiary-color;
        }
        &:active {
            text-decoration: underline;
        }
    }

    &__logo {
        margin-right: 40px;
    }
    &__background {
        position: absolute;
        left: -30px;
        top: -40px;
        z-index: -1;
    }
    &__nav {
        display: flex;
    }
    &__linkLibrary {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            text-decoration: none;
        }
        &:active {
            text-decoration: underline;
        }
        p {
            margin-right: 10px;
            color: $secondary-color;
            font-size: 14px;
            font-weight: bold;
            margin: 0 10px;
            &:hover {
                color: $tertiary-color;
            }
        }
    }
}
