@import "../../../styles/colors.scss";

.message {
    font-size: 0.9rem;
    word-break: break-all;
    margin-bottom: 10px;
    .div {
        display: flex;
        align-items: baseline;
    }
    &__date {
        color: grey;
        margin-bottom: 0;
        font-size: 11px;
    }
    &__author {
        text-transform: capitalize;
        font-weight: bold;
        margin-right: 3px;
        font-size: 0.9rem;
        margin-bottom: 7px;
    }
}

.emoji-picker-react {
    overflow: scroll;
    width: 86%;
}

.nocommunity-message {
    text-align: center;
    width: 86%;
    margin-top: 40%;
    p {
        font-size: 16px;
    }
    .purple-btn {
        background-color: $secondary-color;
        color: $primary-color;
        width: fit-content;
        margin: 50px auto;
        p {
            font-size: unset;
        }
    }

    .purple-btn :hover {
        color: $primary-color;
        width: fit-content;
        margin: 50px auto;
        p {
            font-size: unset;
        }
    }
}

.chatHeader {
    align-items: center;
    width: 86%;
    justify-content: space-between;
    &__commuTitle {
        color: $secondary-color;
        font-size: 20px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        margin-right: 10px;
        &--purple {
            color: $secondary-color;
        }
    }
    a {
        margin-bottom: 5px;
        width: 16px;
        margin: 0;
        img {
            width: 20px;
        }
    }
    &__left {
        align-items: center;
        display: flex;
    }
    &__close {
        width: 16px;
        height: 16px;
        position: relative;
        img {
            width: 16px;
            position: absolute;
            top: 0;
        }
    }
}

.chatCloseCommunityPage {
    &--positionTop {
    width: 100%;
    margin-bottom: 5px;
    }
    &--positionBottom {
        position: fixed;
        z-index: 1;
        bottom: 10px;
        right: 10px;
    }
    width: 25%;
    height: 56px;
    background-color: #fff;
    align-items: center;
    padding: 0 10px;
    display: flex;
    -webkit-animation: conditionalClose 0.5s normal forwards ease-in-out;
    -moz-animation: conditionalClose 0.5s normal forwards ease-in-out;
    animation: conditionalClose 0.5s normal forwards ease-in-out;
    -webkit-transform-origin: 100% 0%;
    -moz-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    p {
        color: $secondary-color;
        font-size: 16px;
        margin-left: 10px;
        font-weight: 700
    }
    img {
        width: 25px;
    }
}


@-webkit-keyframes conditionalClose {
    from {   height: calc(100vh - 279px);
    }
    to { height: 56px; }
  }
  @-moz-keyframes conditionalClose {
    from {   height: calc(100vh - 279px);
    }
    to { height: 56px; }
  }
  @keyframes conditionalClose {
    from {   height: calc(100vh - 279px);
    }
    to { height: 56px; }
  }
