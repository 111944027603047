.sortButtons {
    padding-top: 35px;
    margin-left: 20vw;
    border-bottom: 2px solid $secondary-color;
    width: calc(55vw - 60px);
    display: flex;
    align-items: center;
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border: none;
        background-color: #0f288c9c;
        font-size: 14px;
        font-weight: bold;
        color: #c1cdfc;
        width: 120px;
        height: 35px;
        margin-right: 10px;
        &--active {
            background-color: $secondary-color;
            color: $primary-color;
            border-bottom: 1px solid blue;
        }

    }
}